html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  /* TODO: Better fallbacks! */
  font-family: europa, sans-serif;
  /* TODO: Better solution than this? */
  overflow-y: scroll;
}

a {
  color: #06a;
}

div {
  box-sizing: border-box;
}

strong {
  font-weight: 400;
}

blockquote {
  margin: 0;
  margin-left: 2em;
  font-style: italic;
  position: relative;
}

blockquote::before {
  position: absolute;
  top: -0.2em;
  left: -0.7em;
  content: "“";
  font-family: serif;
  font-size: 4em;
  color: #b9b9b9;
}

blockquote + cite {
  display: block;
  font-size: 0.8em;
  text-align: right;
  font-style: normal;
}
